@import '@doseme/cohesive-ui/dist/style/colors.scss';

.record-course-data-table {
  .co-col-checkbox-only-sm {
    width: 32px;
    min-width: 32px;
  }
}

.recorded-course-data-content {
  max-height: 161px;
  overflow-y: scroll;

  .empty-course-data-cell {
    align-items: flex-start !important;
    white-space: nowrap;
    height: 160px;
  }

  .empty-course-data-message {
    font-style: italic;
    padding-top: 8px !important;
    margin-left: -28px;
  }

  .empty-course-data-row {
    .co-col-checkbox-only-sm {
      visibility: hidden;
    }
  }

  .predicted-outcome-cell {
    .co-col-checkbox-only-sm {
      visibility: hidden;
    }
  }

  .extra-width-predicted-standalone {
    width: 100px;
  }
}

.recorded-course-data-buttons {
  margin-top: 12px;

  .recorded-course-data-buttons-count {
    color: $bright_blue;
  }
}

.predicted-outcome-cell {
  background-color: $pale-orange;
}

.computed-historical-outcome-cell {
  background-color: $pagination-grey;
}

.computed-predicted-outcome-cell {
  background-color: $dropdown-blue;
}

.computed-outcome-border {
  border-left: 3px double $footerblack;
}

.dotted-predicted-data-separator {
  position: relative;

  &:after {
    width: 100%;
    height: 3px;
    content: '';
    position: absolute;
    bottom: -3px;
    border-bottom: 3px dashed $navy !important;
    z-index: 10;
  }
}

.predicted-data-separator {
  border-top: 3px solid $navy !important;
}

// AUC Header Widths
.type-header-col-width-adjust {
  padding-left: 16px !important;
  min-width: 110px;
  width: 25%
}

.time-header-col-width-adjust {
  min-width: 185px;
  width: 35%
}

.amount-header-col-width-adjust {
  min-width: 100px;
  width: 20%
}

.infusion-header-col-width-adjust {
  min-width: 100px;
  width: 20%
}

.dropdown-header-col-width-adjust {
  min-width: 48px;
  max-width: 48px;
}

.predicted-left-header-col-width-adjust {
  padding-left: 15px !important;
  min-width: 81px;
  max-width: 81px;
}

.predicted-right-header-col-width-adjust {
  min-width: 81px;
  max-width: 81px;
}

// AUC Widths
.type-col-width-adjust {
  min-width: 110px;
  width: 25%
}

.time-col-width-adjust {
  min-width: 185px;
  width: 35%
}

.amount-col-width-adjust {
  min-width: 100px;
  width: 20%
}

.infusion-col-width-adjust {
  min-width: 100px;
  width: 20%
}

.dropdown-col-width-adjust {
  min-width: 48px;
  max-width: 48px;
}

.predicted-left-col-width-adjust {
  min-width: 81px;
  max-width: 81px;
}

.predicted-right-col-width-adjust {
  min-width: 81px;
  max-width: 81px;
}

// Cml. AUC Header Widths
.cml-type-header-col-width-adjust {
  padding-left: 16px !important;
  min-width: 110px;
  width: 25%
}

.cml-time-header-col-width-adjust {
  min-width: 185px;
  width: 35%
}

.cml-amount-header-col-width-adjust {
  min-width: 100px;
  width: 20%
}

.cml-infusion-header-col-width-adjust {
  min-width: 100px;
  width: 20%
}

.cml-dropdown-header-col-width-adjust {
  min-width: 48px;
  max-width: 48px;
}

.cml-predicted-left-header-col-width-adjust {
  padding-left: 15px !important;
  min-width: 62px;
  max-width: 62px;
}

.cml-predicted-right-header-col-width-adjust {
  min-width: 100px;
  max-width: 100px;
}

// Cml. AUC Widths
.cml-type-col-width-adjust {
  min-width: 110px;
  width: 25%
}

.cml-time-col-width-adjust {
  min-width: 185px;
  width: 35%
}

.cml-amount-col-width-adjust {
  min-width: 100px;
  width: 20%
}

.cml-infusion-col-width-adjust {
  min-width: 100px;
  width: 20%
}

.cml-dropdown-col-width-adjust {
  min-width: 48px;
  max-width: 48px;
}

.cml-predicted-left-col-width-adjust {
  min-width: 62px;
  max-width: 62px;
}

.cml-predicted-right-col-width-adjust {
  min-width: 100px;
  max-width: 100px;
}

// Peak/Trough Header Widths
.pt-type-header-col-width-adjust {
  padding-left: 16px !important;
  min-width: 110px;
  width: 25%
}

.pt-time-header-col-width-adjust {
  min-width: 185px;
  width: 35%
}

.pt-amount-header-col-width-adjust {
  min-width: 100px;
  width: 20%
}

.pt-infusion-header-col-width-adjust {
  min-width: 100px;
  width: 20%
}

.pt-dropdown-header-col-width-adjust {
  min-width: 48px;
  max-width: 48px;
}

.pt-predicted-left-header-col-width-adjust {
  padding-left: 15px !important;
  min-width: 81px;
  max-width: 81px;
}

.pt-predicted-right-header-col-width-adjust {
  min-width: 81px;
  max-width: 81px;
}

//Peak/Trough Widths
.pt-type-col-width-adjust {
  min-width: 110px;
  width: 25%
}

.pt-time-col-width-adjust {
  min-width: 185px;
  width: 35%
}

.pt-amount-col-width-adjust {
  min-width: 100px;
  width: 20%
}

.pt-infusion-col-width-adjust {
  min-width: 100px;
  width: 20%
}

.pt-dropdown-col-width-adjust {
  min-width: 48px;
  max-width: 48px;
}

.pt-predicted-left-col-width-adjust {
  min-width: 81px;
  max-width: 81px;
}

.pt-predicted-right-col-width-adjust {
  min-width: 81px;
  max-width: 81px;
}

// INR Header Widths
.inr-type-header-col-width-adjust {
  padding-left: 16px !important;
  min-width: 110px;
  width: 25%
}

.inr-time-header-col-width-adjust {
  min-width: 185px;
  width: 35%
}

.inr-amount-header-col-width-adjust {
  min-width: 100px;
  width: 20%
}

.inr-infusion-header-col-width-adjust {
  min-width: 100px;
  width: 20%
}

.inr-dropdown-header-col-width-adjust {
  min-width: 48px;
  max-width: 48px;
}

.inr-predicted-left-header-col-width-adjust {
  padding-left: 15px !important;
  min-width: 81px;
  max-width: 81px;
}

.inr-predicted-right-header-col-width-adjust {
  min-width: 81px;
  max-width: 81px;
}

// INR Widths
.inr-type-col-width-adjust {
  min-width: 110px;
  width: 25%
}

.inr-time-col-width-adjust {
  min-width: 185px;
  width: 35%
}

.inr-amount-col-width-adjust {
  min-width: 100px;
  width: 20%
}

.inr-infusion-col-width-adjust {
  min-width: 100px;
  width: 20%
}

.inr-dropdown-col-width-adjust {
  min-width: 48px;
  max-width: 48px;
}

.inr-predicted-left-col-width-adjust {
  min-width: 81px;
  max-width: 81px;
}

.inr-predicted-right-col-width-adjust {
  min-width: 81px;
  max-width: 81px;
}

.simulated-type-col-width-adjust {
  min-width: 110px;
  width: 25%
}

.action-button-dropdown-tooltip {
  position: relative;
  min-width: 250px;
  left: -230px;
  top: 10px;
}

.duplicate-entry-color {
  color: $doseme-warning;
  font-weight: 700;
}

.predicted-outcomes-units {
  width: 163px;
  height: 24px;
  position: relative;
  float: right;
  background-color: $pagination-grey;
  border-radius: 0 0 6px 6px;
  font-size: 9px;
  text-align: center;
  padding-top: 5px;
  margin-right: 15px;
}

.predicted-outcomes-info-icon {
  position: relative;
  margin-top: 3px;
  margin-left: 5px;
}

.reposition-info-asterisk {
  margin-bottom: 1px;
}

.ellipsis-icon-disabled:hover {
  cursor: not-allowed;
}

.predicted-tab-section {
  width: 175px;
}

.admin-type-subtitle {
  width: 58px;

  &.extra-width {
    width: 70px;
  }
}

.type-tooltip {
  z-index: 100;
}

.time-warning-text {
  color: $doseme-warning;
}

.warning-indicator {
  padding-left: 4px;
  display:inline-block;
  transform: translateY(1px);
}
