@import '@doseme/cohesive-ui/dist/style/colors.scss';

.co-hospital-show-page {
  max-width: 1080px;
  width: auto;
  margin: auto;

  .side-panel {
    width: auto !important;
  }

  .side-panel-title {
    font-size: 20px !important;
  }

  .hospital-show-heading {
    margin-top: 40px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;

    .hospital-show-back-btn {
      display: inline-block;
      margin-right: 16px;
    }

    .hospital-show-title {
      display: inline-block;
      font-size: 24px;
    }
  }

  .hospital-units-roles {
    display: flex;
    flex-wrap: wrap;
  }

  .hospital-show-empty {
    font-style: italic;
    margin-top: 8px;
  }

  .show-hospital-panel-separator {
    border: 1px solid $lightgrey;
    margin-bottom: 12px;
  }

  hr {
    border-top: 1px solid $lightgrey;
  }

  .side-panel-children {
    width: auto;
  }

  .superadmin-info-chevron-outer {
    margin-left: 8px;
    margin-top: 7px;
    color: $loading-grey;
    display: flex;
    font-size: 14px;
    cursor: pointer;

    .superadmin-info-chevron {
      &.activated {
        transform: rotate(180deg);
      }
    }
  }
}

.hospital-show-page-panel-sub-group {
  border-radius: 12px;
  background-color: $menu-grey;
  margin-bottom: 8px;
  padding: 12px 16px;
  font-size: 14px;

  &.taller-h-75 {
    height: 75px;
  }

  .subgroup-details {
    font-size: 14px;
  }

  .active-clinicians-subtitle {
    font-size: 14px;
    color: $charcoal-grey;
    margin-left: 8px;
  }

  .active-clinicians-number {
    color: $footerlink;
    font-weight: 700;
  }

  .active-clinicians-number-text {
    color: $loading-grey;
    font-weight: 700;
    margin-left: 16px;
  }

  .hospital-units-roles-btn {
    margin-left: auto;
    margin-top: 8px;
  }
}

.hospital-show-page-drugs-available-btn-panel {
  border-radius: 12px;
  background-color: $pagination-grey;
  margin-bottom: 8px;
  height: 64px;
  padding-top: 16px;

  .hospital-show-page-side-panel-btn {
    transform: translateX(-50%);
    margin-left: 50%;
  }
}

.mt-negative-20 {
  margin-top: -20px;
}

.display-formatted-details {
  margin-top: 28px;
}

.flex-grow {
  flex-grow: 1;
}

.clipboard {
  justify-content: flex-end;
  width: fit-content;
  margin-top: -2px;
}

.copyToClipboard-btn {
  margin-left: 5px;
  margin-top: -3px;
  padding-left: 6px !important;
}
