@import '@doseme/cohesive-ui/dist/style/colors.scss';

.dose-components-wrapper {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;

  .last-dose-title {
    margin-left: 72px;
  }

  /* This override is necessary for setting tooltip coords on dose recommendation page */
  /* FIXME: Review why co-row sets position:relative */
  .row-position-static {
    position: static;
  }

  .co-col-4 {
    width: 424px;
  }
}

.dose-components-wrapper-no-shrink {
  max-width: 1240px;
}

.dose-components-wrapper-single-column {
  max-width: 800px;

  .activity-log-text-input {
    &.narrow {
      width: 311px;
    }
  }
}

.doserecommendation-title-wrapper {
  display: flex;
  align-items: flex-start;
  margin-top: 24px;
  padding: 0 12px 0 12px;
}

.doserecommendation-info-bubble {
  padding: 0 12px 0 12px;
}

.doserecommendation-title {
  font-size: 24px;
  font-weight: bolder;
  vertical-align: top;
  margin-top: 4px;

  .doserecommendation-title-drug-model-archived {
    font-size: 24px;
    font-weight: 400;
    margin-left: 5px;
  }

  .doserecommendation-page-tags {
    align-items: center;
    display: flex;

    .doserecommendation-tag {
      margin-left: 16px;
    }
  }

  .better-applicable-model-notification {
    background-color: $yellow-100;
    border-radius: 8px;
    border: 2px solid $yellow-300;
    height: 36px;
    width: 466px;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-left: 24px;
  }
}

.side-panel-required-warning-text {
  margin-left: 5px;
  color: $doseme-warning;
  display: inline-block;
  position: relative;
}

.side-panel-text {
  margin-left: 5px;
  color: $grey;
  display: inline-block;
  position: relative;
}

.side-panel-warning-icon {
  margin-left: 6px;
  display: inline-block;
}

.doserecommendation-title-listbutton {
  display: flex;
  padding-left: 14px;

  .co-list-button-lg {
    font-size: 22px;
  }
}

.doserecommendation-title-first-last-dose {
  font-size: 16px;
  font-weight: 400;
  padding-top: 20px;
}

.doserecommendation-bold-title {
  font-weight: 700;
}

.time-modal-input {
  width: 78px;
}

.tablet-view-side-panels {
  margin-right: 34px;
  width: 100%;
}

.activity-log-panel {
  margin-bottom: 24px;
  width: 400px;
}

.panel-single-column {
  width: 100%;
  margin-bottom: 24px;
}

.custom-toast-better-model {
  background-color: $yellow-100;
  font-size: 14;
  font-weight: 700;
  line-height: 16px;
  color: $neutrals-700;
  display: flex;
  padding: 0px;

  .model-suggestion-link {
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
    color: $blue-steel-600;
    display: flex;
    margin-top: 12px;
  }
}

.custom-toast-better-model-outer {
  background-color: $yellow-100 !important;
  border-radius: 8px !important;
  width: 264px !important;
  height: 108px !important;
  border: 2px solid $yellow-300 !important;
  padding: 0px !important;
}
